<template>
  <DataTable
    v-model:filters="filters1"
    v-model:selection="selectedJob"
    paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :rows-per-page-options="[10, 20, 50]"
    current-page-report-template="نمایش {first} تا {last} از {totalRecords} "
    responsive-layout="stack"
    breakpoint="900px"
    :value="jobs"
    :paginator="true"
    :rows="10"
    selection-mode="single"
    data-key="id"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="filters1['global'].value"
          placeholder="جستجو در جدول"
        />
      </span>
    </template>
    <Column field="title" header="عنوان" :sortable="true" style="width: 14%" />
    <Column
      header="توضیحات"
      :sortable="true"
      style="
        width: 14%;
        text-align: right !important;
        direction: rtl !important; ;
      "
    >
      <template #body="slotProps">
        <div
          v-html="slotProps.data.jobRequirementAndDetails.data.description"
        ></div>
      </template>
    </Column>
    <Column
      header="نرم افزار"
      :sortable="true"
      style="
        width: 14%;
        text-align: right !important;
        direction: rtl !important; ;
      "
    >
      <!-- es -->
      <template #body="slotProps">
        <div
          v-for="soft in slotProps.data.jobRequirementAndDetails.data
            .softwareRequirements"
        >
          {{ soft.software.titleFa }}
        </div>
      </template>
    </Column>

    <template #empty> 😢😢😢😢 </template>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import dev from "../assets/jobsList/mechanical.json";
export default {
  data() {
    return {
      columns: null,
      jobs: "",
      selectedJob: null,
      filters1: {},
      page: 1,
      pageSize: 100,
    };
  },

  created() {
    this.getJobsList();
    // console.log(dev);
    this.initFilters1();
  },
  mounted() {},
  methods: {
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    getJobsList() {
      this.jobs = dev;
    },
  },
};
</script>

<style scoped></style>
