<template>
  <jobsList></jobsList>
</template>

<script>
import jobsList from "./components/JobsList.vue";
export default {
  name: "App",
  components: {
    jobsList,
  },
};
</script>

<style></style>
